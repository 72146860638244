<template>
  <div class="accordion">
    <div class="container-header">
      <h3>{{ title }}</h3>
      <b-button v-if="hasDocuments"
          variant="primary h-48 button"
          style="margin-top: -10px"
          @click="openHistoryModal()"
        >Visualizar documentos
      </b-button>
      <div class="chevron" @click="handleOpen()" v-bind:class="{ rotateUp: !open, rotateDown: open }">
        <ChevronUp />
      </div>
    </div>
    <table v-if="this.open && title != 'Solicitação de exames'" class="table-container" v-bind:class="{ openTable: open, closeTable: !open }">
      <thead>
        <th >
          <td v-for="(header, index) in headers" v-bind:key="index" v-bind:class="{ alignRight: header === 'Predominante' }">{{ header }}</td>
        </th>
      </thead>

      <tbody>
        <tr v-for="(item, index ) in items" :key="index">
          <td v-for="(label, i) in labels" :key="label+'-'+i" v-bind:class="{ status: typeof(item[label]) === 'boolean' }">
            <span v-if="label === 'familiar'" class="familiar">
                <h6 v-for="text in item[label]" :key="text">{{ text }}, </h6>
            </span>

            <span v-else-if="label === 'predominante'">
              <span v-if="item[label]">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="24" height="24" rx="12" fill="#FFEBCC"/>
                  <circle cx="12" cy="12" r="8" fill="#FF9900"/>
                </svg>
              </span>

              <span v-else>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="24" height="24" rx="12" fill="#D9DFF2"/>
                  <circle cx="12" cy="12" r="8" fill="#A4B1DF"/>
                </svg>
              </span>
              
            </span>

            <span v-else-if="item[label] === true" >
              <svg width="8" height="8" viewBox="0 0 8 8" class="ellipse"
              fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="4" cy="4" r="4" fill="#00C773"/>
              </svg> Em uso
            </span>

            <span v-else-if="item[label] === false" >
              <svg width="8" height="8" viewBox="0 0 8 8" class="ellipse"
              fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="4" cy="4" r="4" fill="#FF9900"/>
              </svg>Cessado
            </span>

            <span v-else-if="title === 'Antecedentes oftalmológicos' && label !== 'antecedente'" class="updateDate">
              <h6>{{item[label]}}</h6>
              <div>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6667 1.33333C11.6667 0.781043 11.219 0.333328 10.6667 0.333328C10.1144 0.333328 9.66667 0.781043 9.66667 1.33333V1.66666H6.33333V1.33333C6.33333 0.781043 5.88562 0.333328 5.33333 0.333328C4.78105 0.333328 4.33333 0.781043 4.33333 1.33333V1.66666H3.33333C2.04467 1.66666 1 2.71133 1 3.99999V6.66666V13.3333C1 14.622 2.04467 15.6667 3.33333 15.6667H12.6667C13.9553 15.6667 15 14.622 15 13.3333V6.66666V3.99999C15 2.71133 13.9553 1.66666 12.6667 1.66666H11.6667V1.33333ZM13 5.66666V3.99999C13 3.8159 12.8508 3.66666 12.6667 3.66666H11.6667V3.99999C11.6667 4.55228 11.219 4.99999 10.6667 4.99999C10.1144 4.99999 9.66667 4.55228 9.66667 3.99999V3.66666H6.33333V3.99999C6.33333 4.55228 5.88562 4.99999 5.33333 4.99999C4.78105 4.99999 4.33333 4.55228 4.33333 3.99999V3.66666H3.33333C3.14924 3.66666 3 3.8159 3 3.99999V5.66666H13ZM3 7.66666H13V13.3333C13 13.5174 12.8508 13.6667 12.6667 13.6667H3.33333C3.14924 13.6667 3 13.5174 3 13.3333V7.66666Z" fill="#525C7A"/>
                </svg>
                <p>última atualização {{item.ultimaAtualizacao}}</p>
              </div>
            </span>

            <span v-else-if="title === 'Prescrição Medicamentosa'">
              <span v-if="label === 'medicamento' && item[label].product ">{{ item[label].product }}</span>
              <span v-else>{{ item[label] }}</span>
            </span>

            <span v-else-if="title === 'Prescrição Medicamentosa '">
              <span v-if="label === 'medicamento' && item[label].product ">{{ item[label].product }}</span>
              <span v-else>
                 <span v-if="i == 3 && typeof item === 'object'">
                  <span v-if="item.via === 'Ocular'">Pingar {{ item.dose }}</span>
                  <span v-else-if="['Oral', 'Sublingual'].includes(item.via)">{{ item.dose }}</span>
                  <span v-else>Aplicar {{ item.dose }}</span>
                  <span v-if="item.intervalo.includes('De')">
                    a cada
                    {{ item.intervalo.split(/[^0-9]/g).filter(n => n !== '')[0] }}
                    {{ item.intervalo.split(' ')[item.intervalo.split(' ').length - 1] }}
                  </span>
                  <span v-else>
                    {{ item.intervalo }}
                  </span>
                  <span v-if="item.horarios.length">
                    (<span v-for="(horario, index) in item.horarios" :key="index">
                    {{ horario }};
                  </span>)
                  </span>
                  .{{ item ? item.orientacao : '' }}
                </span>
                <span>
                  {{ item[label] }}
                </span>
              </span>
            </span>
            <span v-else-if="title === 'Anotação cirúrgica'">
              <span v-if="label === 'template'">
                <span v-html="item[label]" />
              </span>
              <span v-else>{{ item[label] }}</span>
            </span>

            <span v-else-if="label === 'data'">
              {{ moment(item[label]).isValid() ? moment(item[label]).format('DD/MM/YYYY') : '' }}
            </span>

            <span v-else-if="label === 'updated_at'">
              {{ moment(item[label]).isValid() ? getFormatedTimeStamp(item[label]) : '' }}
            </span>

            <span v-else>
              <span v-html="item[label]" />
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="title === 'Solicitação de exames'">
      <div class="package-card" v-for="(_package, packageIndex) in items" :key="packageIndex">
        <div>{{ _package.name || 'Exames avulsos' }}</div>
        <b-table-simple class="table-container" borderless>
            <b-thead>
              <b-tr>
                <b-th>Exame</b-th>
                <b-th>Lateralidade</b-th>
                <b-th>Comentário</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(exam, index) in _package?.exams " :key="index">
                <b-td>{{ exam['exame'] ?? '-' }}</b-td>
                <b-td>{{ exam['examLateralityModel'] ?? '-' }}</b-td>
                <b-td>{{ exam['comentario'] ?? '-' }}</b-td>
              </b-tr>
            </b-tbody>
            
        </b-table-simple>
        <span v-if="_package.observation">
          Observação: {{ _package?.observation }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { getFormatedTimeStamp } from '@/utils/dateHelper'

export default {
  components: {
    ChevronUp: () => import('@/assets/icons/chevron-up.svg'),
  },
  props: {
    title: String,
    headers: [],
    items: [],
    labels: [], // identificadores do objeto que você quer mostrar na tabela, normalmente são os mesmos nomes dos atributos em headers
    hasDocuments: Boolean,
    attendanceId: String,
  },
  data() {
    return{
      open: true,
    }
  },
  methods: {
    getFormatedTimeStamp,
    handleOpen(){
      this.open = !this.open
    },
    openHistoryModal(){
      this.$emit('openHistoryModal', this.attendanceId)
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion {
  margin: 20px 0px 28px 0px;

  .container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 30px;

    .files-button {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: var(--blue-500);
    }

    h3 {
      font-size: 18px;
      color: var(--dark-blue);
      font-weight: 600;
    }

    .chevron {
      width: 25px;
      height: 25px;

      > svg {
        cursor: pointer;
        stroke: var(--neutral-600);
        transition: .5s;
      }
    }

    .rotateUp {
      animation: rotateUp .5s;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }

    .rotateDown {
      animation: rotateDown .5s;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }

    @keyframes rotateUp {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(180deg);
      }
    }

    @keyframes rotateDown {
      0% {
        transform: rotate(180deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  }

  .package-card {
    border: 1px dashed var(--blue-500);
    border-radius: 8px;
    padding: 1rem;
    margin-top: 1rem;
  }

  .table-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    table-layout: fixed;

    thead {
      border-bottom: 1px solid var(--neutral-300);
    }

    .alignRight {
      text-align: right;
      justify-content: end !important;
      margin-right: 50px;
    }

    .status {
      text-align: right;
      justify-content: end !important;
      span {
        margin-right: 50px;
        .ellipse {
          margin-right: 20px;
        }
      }
    }

    thead, tbody {
      width: 100%;
      margin-top: 10px;
      > th, tr {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        height: 100%;

        td {
          height: 55px;
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          overflow: auto;
          transition: .3s;
          flex-wrap: wrap;
        }
      }

      th td {
        font-size: 14px;
        font-weight: 700;
      }

      tr td {
        font-size: 16px;
        font-weight: 400;
        border-bottom: 1px dotted var(--neutral-300);

        .familiar {
          display: flex;
          flex-direction: row;

          h6 {
            margin-right: 5px;
          }
        }

        .updateDate {
          display: flex;
          flex-direction: column;
          justify-content: center;

          h6 {
            color: var(--type-active);
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 1px !important;
          }

          div {
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: start;
            text-align: top;

            svg {
              margin-right: 5px;
            }
            p {
              color: var(--type-placeholder);
              font-size: 12px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  ::-webkit-scrollbar {
      width: 12px;
      height: 12px;
  }

  ::-webkit-scrollbar-track {
      background: var(--neutral-000);
      border-radius: 0 0 8px 0;
  }

  ::-webkit-scrollbar-thumb {
      background-color: var(--neutral-300);
      border-radius: 100px;
      border: 3px solid var(--neutral-000);
  }

  ::-webkit-scrollbar-thumb:hover {
      cursor: pointer;
      background: var(--neutral-200);
  }
}
</style>
